import { colors } from "@mui/material";

const dColor1 = '#0D00A4';
const dColor2 = '#4169E1';
const dColor3 = '#3298dc';

const dColor4 = '#ef233c';
const dColor5 = '#bc4749';

export default {
    dColor1,
    dColor2,
    dColor3,
    dColor4,
    dColor5,
};