import bcrypt from 'bcryptjs';

export const hashPassword = async (password) => {
    const salt = await bcrypt.genSalt(10);
    const hashedPassword = await bcrypt.hash(password, salt);
    return hashedPassword;
};

export const comparePasswords = async (enteredPassword, password) => {
    const isMatch = await bcrypt.compare(enteredPassword, password);
    console.log(isMatch);
    return isMatch;
}